import styled from 'styled-components';
import {Col} from 'react-bootstrap';
import { ImageUrl } from '../../utils/constants';
const backgroundUrl = `url(${ImageUrl}/images/HomePageV2/tailored-background.png)`;

const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const Section = styled.div`
  float: left;
  width: 100%;
  padding-top: 100px;
  display: block;
  font-family: ${RegularFont};
  display: ${({intelligenceSection}) => intelligenceSection ? 'block' : 'flex'};
  align-items: flex-start;
  position: relative;
  background-image: linear-gradient(#FBFEFF, #F7FCFF);
  @media (max-width: 991px) {
    display: block;
  }
  @media (max-width: 1201px) {
    flex-direction: column
    padding-top: 60px;
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1600px) / 2);
    padding-right: calc(calc(100% - 1600px) / 2);
  }
`;

const IntroTextContainer = styled.div`
  display: inline-block;
  float: left;
  width: 40%;
  letter-spacing: 1.5px;
  padding: 0 10px 30px 150px;
  h1 {
     margin-bottom: 25px;
     font-size: 35px;
     color: #2b281e;
     font-family: ${BoldFont};
     letter-spacing: 0;
     margin-top: 10px;
     font-weight: 700;
     line-height: 40px;
     max-width: 350px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    color: #2b281e;
    font-family: ${RegularFont};
    letter-spacing: 0;
    font-weight: 400;
    margin-bottom: 65px;
    max-width: 350px;
  }
  .wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  @media (max-width: 1201px) {
    width: 100%;
    padding: 0 90px 30px 98px; 
    h1, p {
      max-width: unset;
    }
    .wrap {
      width: 60%;
    }
  }
  @media (max-width: 1000px) {
      padding: 30px 6vw;
  }  
  @media (max-width: 600px) {
      padding-top: 10px;
      .wrap {
        width: 100%;
      }
  }
  @media (max-width: 350px) {
    .wrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  @media (min-width: 1420px) {
    h1 {
      max-width: unset;
      font-size: 45px;
      letter-spacing: 1px;
      line-height: 48px;
    }
    p {
      margin-bottom: 80px;
      max-width: 415px;
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

const PosterImage = styled.div`
  display: inline-block;
  float: left; 
  padding: 0 80px 0 0px;
  width: 60%;
  
  img {
    width: 100%;
    margin-bottom: -35px;
    max-height: 500px;
    min-height: 420px;
  }
  
  @media (max-width: 1201px) {
    width: 100%;
    padding: 0px;
    
    img {
      width: 100%;
      margin-bottom: -70px;
      padding: 0 90px 20px 98px;
      min-height: unset;
      max-height: unset;
  }
  }
  
  @media (max-width: 1000px) {
    img {
      padding: 30px 6vw;
    }
  }
  @media (max-width: 768px) {
    img {
      margin-bottom: -60px;
    }
  }
  @media (max-width: 500px) {
    img {
      margin-bottom: -50px;
    }
  }
`;

const PromotionalContainer = styled.div`
  width: 100%;
  min-height: 200px;
  background-image: linear-gradient(#E6F6FF, #CFEDFF);
  float: left;
  padding: 30px 90px 30px 98px;
  
 > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
      span, div {
            margin: 55px 15px 0px 15px;
            font-size: 16px;
            letter-spacing: 1.4px;
            color: #1e577a;
            font-family: ${BoldFont};
            text-align: center;
            font-weight: 700;
      }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;

const LogoContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height ? props.height : 'auto'};
  img {
    width: 100%;
    height: 100%;
  }
`;

const InformationContainer = styled.div`
  width: 100%;
  float: left;
  background-color: #F6F6F8;
  padding: 60px 0px 55px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ? props.marginTop : '0px'};
  h3 {
    font-family: ${MediumFont};
    margin-bottom: 15px
    font-size: 34px;
    font-weight: 700;
    color: #2b281e;
    text-align: center;
  }
  
  p {
    text-align: center;
    width: 50%;
    margin-top: 15px;
    font-family: ${RegularFont};
    font-size: 20px;
    color: #2b281e;
    font-weight: 400;
    max-width: 640px;
  }
  
  img {
    width: 100%;
    margin-top: 25px;
  }
  .grassRootImageContainer {
    text-align: center;
    img {
      width: 90%;
      margin-top: 60px;
    }
  }
  .grassRootText {
    width: 60%;
    max-width: unset;
  }
  @media (max-width: 1120px){
    padding: ${(props) => props.paddingBottom ? '60px 0px 0px 0px' : '60px 0px 55px 0px'};
    p {
      width: 60%;
    }
  } 
  @media (max-width: 1000px){
    padding: ${(props) => props.paddingBottom ? '60px 0px 0px 0px' : '60px 6vw 55px 6vw'};
    p, .grassRootText {
      width: 65%;
    }
  }
  @media (max-width: 785px){
    padding: 60px 0px 55px 0px;
  }
  @media (max-width: 600px) {
    p, .grassRootText {
      width: 80%;
    } 
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1600px) / 2);
    padding-right: calc(calc(100% - 1600px) / 2);
  }
  .tailorBlock {
    text-align: center;
    img {
      width: 65%;
    }
  }
`;

const PlatformInformation = styled.div`
  float: left;
  margin-top:${(props) => props.marginTop ? props.marginTop : '100px'};
  width: 100%;
  h4 {
    text-align: center;
    font-size: 26px;
    color: #2b281e;
    font-family: ${MediumFont};
    padding: 0px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 2px;
  }
  @media (max-width: 1000px){
    margin-top: 80px;
  }
`;

const PlatformItemContainer = styled.div`
    float: left;
    width: 100%;
    padding: 0px 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    @media (max-width: 1000px) {
      padding: 0px 6vw;
    }
    @media (max-width: 500px) {
      padding: 0px 10px;
    }
`;

const PlatformItem = styled.div`
    float: left;
    margin: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 230px;
    h4 {
        text-align: center;
        color: #2b281e;
        font-family: ${BoldFont};
        font-size: 22px;
        margin-top: 40px;
        font-weight: 700;
    }
    p {
        text-align: center;
        width: 100%;
        max-width: 232px;
        color: #2b281e;
        font-size: 14px;
        margin-top: 5px;
        font-weight: 400;
    }
    a {
       color: #f27c06;
       margin-top: 20px;
       cursor: pointer;
       font-weight: 700;
       font-size: 15px;
       font-family: ${BoldFont};
    }
    picture {
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      img {
        height: ${(props) => props.iconWidth ? '60px' : '100%'};
        width: ${(props) => props.iconWidth ? '60px' : '100%'};
      }
    }
    > div {
      text-align: center
    }
`;

const GrassRootItem = styled.div`
    float: left;
    margin: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 430px;
    justify-content: ${(props) => props.justifyContent ? props.justifyContent : 'space-between'};
    p {
      text-align: center;
      width: 100%;
      max-width: 210px;
      color: #2b281e;
      font-size: 15px;
      font-weight: 400;
      span {
        font-family: ${BoldFont};
      }
    }
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
    div {
      text-align: center
    }
    @media (max-width: 889px) {
      min-height: 380px;
    }
    @media (max-width: 522px) {
      min-height: unset;
    }
`;

const HealthContainer = styled.div`
  float: left;
  width: 100%;
  height: 600px;
  ${'' /* background: url(../../images/HomePageV2/tailored-background.png); */}
  background: ${backgroundUrl};
  background-size: 100% 100%;
  @media (min-width: 1400px) {
    height: 650px;
  }
  @media (max-width: 1201px) {
    height: 980px;
    display: flex;
    flex-direction: column;
    align-items: center;
   }
  @media (max-width: 768px) {
      height: 900px;
  }
  @media (max-width: 630px) {
      height: 950px;
  }
  @media (max-width: 500px) {
      height: 1200px;
  }
  
  @media (max-width: 400px) {
      height: 1300px;
  } 
  .roundItemContainer {
    width: 60%;
    height: 100%;
    float: right;
    position: relative;
    .roundContainer {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      display: flex;
      position: absolute;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 12px 0px;
       p {
        font-size: 12px;
        color: #ffffff;
        font-weight: 900;
        font-family: ${BoldFont};
        margin-bottom: 0px;
        margin-top: 10px;
       }
       img {
        position: static;
        opacity: 1;
       }
    }
    .first {
      background: #c464ac;
      top: 7%;
      left: 40%;
      img {
        width: 19px;
        height: 41px;
        opacity: 1;
      }
    }
    .second {
      background: #f4d463;
      bottom: 7%;
      left: 40%;
      img {
        width: 50px;
        height: 30px;
        opacity: 1;
      }
    }
    .third {
      background: #7164c4;
      top: 25%;
      left: 20%;
      img {
        width: 45px;
        height: 35px;
        opacity: 1;
      }
    }
    .fourth {
      background: #e48d8b;
      bottom: 25%;
      left: 20%;
      img {
        width: 41px;
        height: 45px;
        opacity: 1;
      }
    }
    .fifth {
      background: #4a9dc6;
      top: 25%;
      right: 22%;
      img {
        width: 50px;
        height: 25px;
        opacity: 1;
      }
    }
    .six {
      background: #8bbd53;
      bottom: 25%;
      right: 22%;
      img {
        width: 35px;
        height: 40px;
        opacity: 1;
      }
    }
    img {
      width: 100px;
      height: 80px;
      position: absolute;
      top: 40%;
      left: 42%;
      opacity: ${({opacity}) => opacity && '0.4'};
    }
    @media (min-width: 1400px) {
      .fifth, .six {
        right: 24%;
      }
    }
    @media (min-width: 1560px) {
      .fifth, .six {
        right: 26%;
      }
      img {
        left: 41.5%;
      }
    }
    @media (max-width: 1201px) {
        width: 100%;
        max-width: 768px;
        height: 70%;
      }
    @media (max-width: 768px){
        img {
              left: 40.5%;
           }
         .fifth, .six {
              right: 25%;
         }  
        .roundContainer {
           width: 110px;
           height: 110px;
           p {
              font-size: 11px;
           }
        }
    }  
    @media (max-width: 630px){
      .third, .fourth {
        left: 15%
      }
      .fifth, .six {
        right: 18%
      }
    }
    @media (max-width: 550px){
      .fifth, .six {
        right: 13%
      }
    }
    @media (max-width: 500px){
      .third, .fourth {
        left: 5%
      }
      .fifth, .six {
        right: 5%
      }
    }
    @media (max-width: 450px){
      .first, .second {
        left: 37%
      }
    }
    @media (max-width: 400px){
      img {
        left: 38.5%;
      }
    }
    @media (max-width: 350px){
      img {
        left: 36.5%;
      }
    }
    @media (max-width: 360px){
      .third, .fourth {
        left: 0%
      }
      .fifth, .six {
        right: 0%
      }
    }
  }
  
  .tailoredTextContainer {
    float: left;
    width: 40%;
    padding: 100px 0px 30px 85px;
    h1 {
      font-size: 32px;
      color: #fff;
      font-weight: 700;
      line-height: 35px;
      font-family: ${BoldFont};
      line-height: 40px;
      > span {
        margin-bottom: 22px;
        display: inline-block;
      }
    }
    p {
      font-family: ${RegularFont};
      font-size: 20px;
      color: #fff;
      padding-top: 40px;
      font-weight: 400;
    }
    @media (min-width: 1400px){
      h1{
        max-width: 425px;
        font-size: 34px;
      }
      p {
        max-width: 425px;
        font-size: 22px;
      }
    }
    @media (max-width: 1201px) {
        width: 100%;
        padding: 30px 90px 0px 98px;
        height: 30%;
        h1 {
          width: 55%;
        }
        p {
          padding-top: 0px;
        }
      }
   @media (max-width: 800px){
      h1 {
          width: 80%;
        }
   }
   @media (max-width: 600px){
      h1 {
          width: 90%;
        }
   }   
   @media (max-width: 1000px){
      padding: 30px 6vw;
    }   
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1600px) / 2);
    padding-right: calc(calc(100% - 1600px) / 2);
  }
`;

const GetStartedContainer = styled.div`
  float: left;
  width: 100%;
  padding: 80px 0px;
  background-image: linear-gradient(#05344D, #136680);
  display: flex;
  justify-content: center;
 > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      font-size: 15px;
      width: 240px;
    }
    h3 {
    color: #fff;
    font-size: 33px;
    line-height: 36px;
    text-align: center;
    font-family: ${RegularFont}
  }
  }
 
`;

const ImageContainer = styled.div`
  width: ${({fullWidth}) => fullWidth ? '80%' : '50%'};
  padding: ${({left}) => left ? '0' : '0 70px 0 15px'};
  margin: ${({fullWidth}) => fullWidth ? 'auto' : '0'};
  float: ${({fullWidth}) => fullWidth ? 'unset' : 'left'};

  @media (max-width: 1000px) {
    padding: ${({left}) => left ? '0' : '0 15px'};
  }
  
  > picture {
    > img {
      width: 100%;
    }
  }
  
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Button = styled.button`
  margin-top: ${({noMargin}) => noMargin ? '0px' : '25px'};
  width: 37%;
  background-color: #1573B9;
  color: white;
  padding: 13px 10px;
  border-radius: 40px;
  border: none;
  font-size: 13px;
  font-family: ${BoldFont};
  font-weight: 700;
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 350px) {
    width: 59%;
    padding: 14px 10px;
  }
  
  @media (min-width: 1420px) {
    font-size: 15px;
    padding: 15px 10px;
  }
`;

const VideoButton = styled.button`
  width: 59%;
  background-color: transparent;
  color: #1573B9;
  padding: 13px 10px;
  border-radius: 40px;
  border: 1px solid #1573B9;
  font-size: 13px;
  font-family: ${BoldFont};
  font-weight: 700;
  display: none;
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  i {
    margin-right: 10px;
  }
  @media (max-width: 350px){
    margin-top: 15px;
  }
  @media (min-width: 1420px) {
    font-size: 15px;
    padding: 15px 10px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  float: left;
  text-align: center;
`;

const ImageWrapper = styled(Col)`
  width: 100%;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  > img {
    width: 100%;
    max-width: 450px;
    cursor: pointer;
  }
`;

export {
  Section,
  ImageContainer,
  Button,
  ButtonContainer,
  ImageWrapper,
  IntroTextContainer,
  VideoButton,
  PosterImage,
  PromotionalContainer,
  InformationContainer,
  PlatformInformation,
  PlatformItemContainer,
  PlatformItem,
  LogoContainer,
  HealthContainer,
  GrassRootItem,
  GetStartedContainer
};